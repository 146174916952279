/*
 * 업무구분: 수익증권>영업>그룹신규>약정이체계좌등록
 * 화 면 명: MSPFS305M
 * 화면설명: 약정이체계좌등록
 * 작 성 일: 2023.03.17
 * 작 성 자: 지재호
 * 파 일 명: CU119000M.xfdl
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="약정이체계좌등록" :topButton="true">
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top gap40">
          <div class="left column mr0">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label> 고객번호 </label>
                <mo-text-field class="w150"  
                  numeric
                  v-model="lv_ed_cust_no.value"
                  maxlength="7" 
                  ref="ed_cust_no"
				          type="number"
                  @keyup="[fn_SearchValid($event),fn_validErrRemoveInput($event, 'ed_cust_no')]"
                  @paste="fn_SearchValid($event, 'P')"
                /> 
                <mo-button class="w61" @click="fn_OpenPopup(0)"> 고객정보 </mo-button>
                <mo-text-field class="w130"
                v-model="cust_nm1"
                ref="cust_nm1"  disabled />
              </div>
              <div class="wrap-input row">
                <label class="emphasis" > 비밀번호 </label>
                <m-trans-key-input
                  numeric
                  v-if="isMobile && isMtrans"
                  class="w80"
                  v-model="Ed_password"
                  type="numberMax4"
                  dialog="Y"
                  start="0" 
                  end="-1"
                  :isClear="lv_isClear"
                  @keyup="fn_validErrRemoveInput($event, 'Ed_password')"
                  @masked-txt="fn_SetMaskedTxt"         
                  ref="Ed_password"
                />
                <mo-text-field 
                  v-else
                  class="w130" 
                  type="password"
                  maxlength="4" 
                  v-model="Ed_password"
                  @keyup="fn_validErrRemoveInput($event, 'Ed_password')"
                  ref="Ed_password" 
                />
              </div>
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row flex-unset">
                <label class="emphasis"> 유선구분 </label>
                <mo-radio v-model="Rdo_ars_gb_true" class="flex-unset mw-auto mr14"> 일반 </mo-radio>
                <mo-radio class="flex-unset mw-auto" disabled> 유선 </mo-radio>
              </div>
              
              <!-- 대리인구분 -->
              <div class="wrap-input row">
                <label class="emphasis"> 대리인여부 </label>
                <mo-radio-wrapper
                  :items="itemsAgent"
                  v-model="lv_rdo_agnt_chk_yn.value"
                  @input="fn_validErrRemoveRadio($event, 'rdo_agnt_chk_yn')"
                  ref="rdo_agnt_chk_yn"
                  class="row gap8"
                />
              </div>
            </div>
          </div>
          <div class="right column gap10">
            <div class="row w100p h28"></div>
            <div class="row w100p">
              <div class="wrap-button row">
                <mo-button class="clear" @click="fn_init"> 초기화 </mo-button>
                <mo-button primary class="inquiry" @click="fn_searchList"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title"> 은행계좌 </h2>
        </div>
        <div class="wrap-table">
          <table class="table th-wsN th-white">
            <tbody>
              <tr>
                <th class="w190"> 명의자구분 </th> 
                <td>
                  <div class="wrap-input row gap40"> 
                    <div class="row"> 
                      <mo-radio-wrapper 
                          :disabled="enabledList.Rad_per_gb==false"
                          :items="Ds_1190_per_gb" 
                          v-model="Rad_per_gb" 
                          @input="[fn_validErrRemoveRadio($event, 'Rad_per_gb'),Rad_per_gb_onitemchanged($event)]"
                          class="row gap6"
                          ref="Rad_per_gb"
                        />
                    </div>
                    <mo-checkbox v-model="Chk_btrans_acct_nm_diff_yn" class="ml46">예금주명 상이</mo-checkbox> 
                  </div>
                </td>
              </tr>
              <tr>
                <th> 은행코드 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown class="w200"
                      :disabled="enabledList.Cob_btrans_bank_cd==false"
                      :items="lv_commCode.code989" 
                      v-model="Cob_btrans_bank_cd"
                      @input="fn_validErrRemoveRadio($event, 'Cob_btrans_bank_cd')"  
                      ref='Cob_btrans_bank_cd'
                      placeholder=" "
                    /> 
                  </div>
                </td>
              </tr>
              <tr>
                <th> 계좌번호 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w200" 
                      numeric
                      :disabled="enabledList.Ed_btrans_acct_no==false"
                      v-model="Ed_btrans_acct_no" 
                      type="number"
                      maxlength="20" 
                      @keydown="Ed_btrans_acct_onkeydown($event)"
                      @keyup="fn_validErrRemoveInput($event, 'Ed_btrans_acct_no')"
                      ref="Ed_btrans_acct_no"
                    /> 
                    <mo-text-field class="w200" 
                      v-model="Ed_btrans_acct_nm"
                      :disabled="enabledList.Ed_btrans_acct_nm==false"
                      @keyup="fn_validErrRemoveInput($event, 'Ed_btrans_acct_nm')"
                      ref="Ed_btrans_acct_nm"
                     /> 
                    <mo-button
                      :disabled="enabledList.btn_cust_nm==false"
                      @click="btn_cust_nm_onclick"
                      ref="btn_cust_nm"
                    >예금주 확인</mo-button> 
                    <mo-text-field class="w200" 
                      :disabled="enabledList.Ed_cust_nm==false"
                      v-model="Ed_cust_nm" 
                      @keyup="fn_validErrRemoveInput($event, 'Ed_cust_nm')"
                      ref="Ed_cust_nm"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-table mt16 h-scroll"> <!-- class명 추가 "h-scroll" 2023-04-12 jy -->
          <table class="table row-type">
            <thead>
                <tr>
                  <th class="w74"> 은행코드 </th>
                  <th> 은행명 </th>
                  <th> 계좌번호 </th>
                  <th> 예금주 </th>
                  <th> BP계좌 ID </th>
                  <th> 계좌주BP여부 </th>
                  <th> 계좌주BP ID </th> <!-- 문구수정 "계좌주RP ID > 계좌주BP ID" 2023-04-19 jy -->
                  <th> 등록채널 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in Ds_1190_detail" :key="i">
                  <!-- <td> {{row.trd_bank_cd}} </td> -->
                  <td> {{row.trd_bank_cd}} </td> 
                  <td> {{row.trd_bank_nm}} </td>
                  <td> {{gfn_accnoReplace(row.bnk_accn_no_encr, true)}} </td>
                  <td> {{row.bank_acct_nm}} </td>
                  <td> {{row.acct_id}} </td>
                  <td> {{row.acct_bp_yn}} </td>
                  <td> {{row.cust_id}} </td>
                  <td> {{row.trt_path_cdnm}} </td>
                </tr>
              </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <div class="left">
            <mo-button v-if="lv_isProcess"
              point size="large"
              @click="fn_endProc()"              
            > 완료 </mo-button>
          </div>
          <mo-button primary size="large" 
            :disabled="enabledList.btn_process==false"
            @click="fn_process"
          > 처리 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>
    
    <!-- popup308 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>

    <!-- popup313 -->
    <msp-fs-313p
      ref="popup313"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup313Obj"
    ></msp-fs-313p>

    <mo-modal class="fts-modal small" ref="modal1">
      <template>
        삭제하시겠습니까?
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button size="large" @click="closeModal(1)">아니오</mo-button>
          <mo-button primary size="large">예</mo-button>
        </div>
      </template>
    </mo-modal>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSHeader from '@/ui/fs/comm/FSHeader'
import FSCommUtil from '@/ui/fs/comm/FSCommUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSAlertPopup from '@/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)  
import FSServiceUtil from '@/ui/fs/comm/FSServiceUtil'  
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)
import MSPFS308P from '~/src/ui/fs/MSPFS308P'  // 고객정보 팝업
import DataSet305M from '~/src/ui/fs/dts/DataSet305M' // dataSet 정리
import MSPFS313P from '~/src/ui/fs/MSPFS313P'  // 업무대리인 조회 팝업
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS305M",
  screenId: "MSPFS305M",
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo,
    'msp-fs-308p': MSPFS308P,
    'msp-fs-313p': MSPFS313P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    let t_bfcer_cust_no = '' // 1001095 프로세스 테스트용  

    this.$nextTick(()=>{
      if( !FSCommUtil.gfn_isNull(this.$route.params.bfcer_cust_no) || !FSCommUtil.gfn_isNull(t_bfcer_cust_no)) {
        this.lv_ed_cust_no.value = this.$route.params.bfcer_cust_no
        if(!FSCommUtil.gfn_isNull(t_bfcer_cust_no)){
          this.lv_ed_cust_no.value = t_bfcer_cust_no
        }

        // 고객번호 매핑 후 조회
        this.fn_SearchValid({key: 'v'})
      }      
    })

  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS305M");
    this.fn_afterFormOnload()

    if( !FSCommUtil.gfn_isNull(this.$route.params.ed_cust_no) ) {
      setTimeout(() => {
        this.lv_ed_cust_no.value = this.$route.params.ed_cust_no

        // 고객번호 매핑 후 조회
        this.fn_SearchValid({key: 'v'}, 0)
      }, 100)
    }

    // 유저 정보 세팅
    // let vm = this
    // vm.$bizUtil.fsUtils.selBasInfo(vm, false).then(function (response) {
    //   vm.lv_basInfo = response.body
    //   vm.gUser_group_cd = vm.lv_basInfo.userGrpTc //this.gfn_userinfo("userGrpTc")
    // }).catch(function (error) {
    //   window.vue.error(error)
    // })
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 로그인정보
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      lv_userInfo: this.getStore('userInfo').getters.getUserInfo,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
      lv_isClear: false,                        // 보안키패드 초기화
      lv_masked_val: '',                        // 보안키패드 '*' 치환값
      pHeaderObj: {     
        title: '약정이체계좌등록'
        ,step: 8
      },
      lv_commCode: {},
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),  // EAI(PO) 호출 객체
      divArsObj: {
        ctrlPwd: '',
        Rdo_ars_gb: '',
      },
      Rdo_ars_gb: 'N',                          // 유선 구분 일반(N) 값으로 고정
      Rdo_ars_gb_true: true,                    // 유선구분 라디오 버튼 고정
      divAgntObj: {},
      pAlertPopupObj: {},                       // 얼럿 모달 팝업 객체
      pCustInfoObj: {},                         // 고객정보 팝업 객체
      pPopup308Obj: {},                         // 고객정보 팝업 객체
      pPopup313Obj: {},                         // 대리인여부 팝업 객체       
      // lv_ed_cust_nm: {},                     // 고객명
      Ed_btrans_acct_no: '',                    // 계좌번호
      Ed_btrans_acct_nm: '',                    // 예금주명
      Ed_cust_nm: '',                           // 예금주명 확인
      Cob_btrans_bank_cd: '',                   // default 한국은행 
      Ed_password: '',                          // 패스워드
      cust_nm1: '',                             // 이름
      cust_nm2: '',                             // 이름
      Ed_rname_no: '' ,                         // 실명번호 , 화면상에서는 사라짐. 
      Chk_btrans_acct_nm_diff_yn: false,        // 예금주명 상이 
      Rad_per_gb: '',                           // 명의자 구분
      lv_rdo_agnt_chk_yn: {                     // 대리인여부
        value: '',
      },       
      lv_ed_cust_no: {                          // 고객번호
        value:''
      },                                        
      
      // 전역 
      gUser_group_cd : '', // 관리자기능 모두 사용가능하게 함, this.getStore('fsStore').getters.getBasInfo.data.userGrpTc,
      check_nm: '',
      pScrNo: '',
      pCustNo: '',
      pSecu: '',
      vService_gubun: '',   //레포트에서 사용하기 위함
      gArs_pswn_yn: '',

      // endable List 
      enabledList: {
        btn_searchList: '',
        btn_process: '',
        btn_init: '',
        ctrlPwd: '',
      },

      // dataSetSet 선언
      Ds_1190_detail: '',
      Ds_1190_head: Object.assign({}, DataSet305M.Ds_1190_head),
      Ds_cust_info: Object.assign({}, DataSet305M.Ds_cust_info),
      Ds_cu1190: Object.assign({}, DataSet305M.Ds_cu1190),
      DS_form_list: Object.assign({}, DataSet305M.DS_form_list),
      Ds_cu1190_per_gb: Object.assign({}, DataSet305M.Ds_cu1190_per_gb),
      Ds_cu1190_head: Object.assign({}, DataSet305M.Ds_cu1190_head),
      Ds_cu1190_detail_tmp: Object.assign({}, DataSet305M.Ds_cu1190_detail_tmp),
      Ds_2040_head: Object.assign({}, DataSet305M.Ds_2040_head),
      DS_FORM_HEAD: Object.assign({}, DataSet305M.DS_FORM_HEAD),
      Ds_1190_body_data: Object.assign({}, DataSet305M.Ds_1190_body_data),

      // Accordion Toggle
      isActive: false,

    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    lv_isProcess() {
      return this.getStore('fsStore').getters.getState.isProcess
    },

    // 업무대리인
    itemsAgent() {
      let rtn = []
      rtn.push({value: 'Y', text: '예'})
      rtn.push({value: 'N', text: '아니오'})
      return rtn
    },

    Ds_1190_per_gb() {
      let rtn = []
      rtn.push({value: 'P', text: '본인'})
      rtn.push({value: 'O', text: '타인'})
      return rtn
    },

    // modal
    modal1() {return this.$refs.modal1},
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 대리인여부
    'lv_rdo_agnt_chk_yn.value': {
      handler(after, before) {
        if( after == 'Y' ) {
          // 대리인 만기체크
          this.fn_AgentCheck()
        }
      },
      deep: true
    },

    /**********************
     * BindItem 대응
     *********************/
    Ed_cust_nm: function(after, before){ // 예금주명 확인

      // error css 삭제
      const value = after
      const targetEl = this.$refs['Ed_cust_nm'].$el
      if (!FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(value)))
        targetEl.classList.remove("error");
    },

    /**********************************************************************************
     * 프로세스 체크
     **********************************************************************************/
    'lv_isProcess': {
      handler(after, before) {
        console.log('lv_isProcess > ', after)
        this.fn_init()
      },
      deep: true
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {

    /********************************************************************************************************************
    * Function   : gfn_accnoReplace
    * Parameter  : 종합계좌 replace
    ********************************************************************************************************************/
    gfn_accnoReplace ( val , mask ) {
      let result = ''
      let t_one = ''
      let t_two = ''
      let t_three = ''
      if ( mask == true ) {
        if ( val.length >= 8 ) {
          t_one = FSCommUtil.gfn_substr(val, 0, 3).toString()
          t_two = FSCommUtil.gfn_substr(val, 3, 5).replaceAll(/[0-9]/g,'*').toString()
          t_three = FSCommUtil.gfn_substr(val, 5, val.length - 5).toString()
          result = t_one + t_two + t_three
        } else {
          t_one = FSCommUtil.gfn_substr(val, 0, 3).toString()
          t_two = FSCommUtil.gfn_substr(val, 3, 7).replaceAll(/[0-9]/g,'*').toString()
          result = t_one + t_two
        }
      } else {
        result = val
      }
      
      return result
    }, 

    /******************************************************************************
    * Function명 : fn_SetMaskedTxt
    * 설명       : 보안키패드 체크
    ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if(this.lv_masked_val.length >= 4) {
        this.fn_searchList()
      }
    },
    
    /******************************************************************************
     * Function명 : fn_validErrRemoveInput
     * 설명       : input, mo-text-field 컴포넌트의 css error class 삭제
     ******************************************************************************/
    fn_validErrRemoveInput(event, el) {
      const value = event.currentTarget.value;
      const targetEl = this.$refs[el].$el;
      if (!FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(value)))
        targetEl.classList.remove("error");
    },

    /******************************************************************************
     * Function명 : fn_validErrRemoveRadio
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    fn_validErrRemoveRadio (value, el) {
      const targetEl = this.$refs[el].$el
      if ( ! FSCommUtil.gfn_isNull( FSCommUtil.gfn_trim(value) ) )
        targetEl.classList.remove('error')
    },

    fn_afterFormOnload (){
      // 공통코드 셋팅
      this.fn_SetCommCode();
      // 버튼세팅
      // this.fn_commonBtnList();
      // 버튼 제어
      this.fn_enableComponent();
      //초기화
      this.fn_init();
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId':'989', 'dayChkYn':'N'},
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult

      // 은행코드 설정
      let t_codeList = []
      pResult.code989.forEach((item) => {
        t_codeList.push(item)
      })
      this.lv_commCode.code989 = t_codeList
    },
   
    /**********************************************************************************
     * WFtitleCRUDbtn Div 의 버튼 활성,비활성 처리
     **********************************************************************************/
    fn_enableComponent ()
    {
      this.enabledList.btn_searchList = true
      this.enabledList.btn_process = false
      this.enabledList.btn_init = true
    },

    /******************************************************************************
     * Function명 : fn_rmAllErrCss
     * 설명       : 해당 컴포넌트 error css 삭제
     ******************************************************************************/
    fn_rmAllErrCss(){
      for ( let idx in this.$refs ) {
        this.$refs[idx].$el.classList.remove("error")
      }
    },

    //==============폼화면설정끝=============================

    //==============사용자 이벤트=============================
    /**********************************************************************************
    * 초기화
    ***********************************************************************************/
    fn_init ( initType ) {
      //버튼 제어
      this.fn_enableComponent();
      
      this.Ds_1190_detail = ''
      this.Ds_1190_head = Object.assign({}, DataSet305M.Ds_1190_head)
      this.Ds_1190_head.tr_code = "CU1190"
      this.gArs_pswn_yn = "";

      // 조회에서 호출할 경우 조회영역은 초기화 하지않음
      if ( initType != 'search' ) {
        this.$refs.fsCustInfo.fn_Init() // 고객정보 초기화
        this.lv_ed_cust_no.value = ''
        this.cust_nm1 = ''
        this.cust_nm2 = ''
        this.Ed_password = ''
        this.lv_isClear = !this.lv_isClear ? true : false // 보안키패드 초기화
        this.lv_rdo_agnt_chk_yn.value = ''
      }
      
      // model 초기화
      this.Ed_btrans_acct_no = ''
      this.Ed_btrans_acct_nm = ''
      this.Ed_cust_nm = ''
      this.Ed_rname_no = ''
      this.Cob_btrans_bank_cd =''
      this.Chk_btrans_acct_nm_diff_yn = false

      // enabled list 목록
      this.enabledList.Rad_per_gb = false
      this.enabledList.Cob_btrans_bank_cd = false
      this.enabledList.Ed_btrans_acct_no = false
      this.enabledList.Ed_btrans_acct_nm = false
      this.enabledList.btn_cust_nm = false
      this.enabledList.Ed_cust_nm = false
      // application.gv_adm_eno = "";
      // application.gv_adm_seq_no = "";

      this.Rad_per_gb = this.Ds_1190_per_gb[0].value
      this.Rad_per_gb_onitemchanged()

      // 에러 초기화
      this.fn_rmAllErrCss()
    },

    /***************************************************************************************
      ARS 유선구분 컨트롤 콜백 : ARS 컨트롤 값 변경시 호출
    *****************************************************************************************/
    cbArsValueChanged () {
      var strSelectMxmbValue = this.$refs.div_ars.getValue();		// 유무선 구분값(N : 일반, Y : 유선)
      this.gArs_pswn_yn = "";
      if(strSelectMxmbValue == "Y") {
        this.Ed_password = ''
      }
    },

    /***************************************************************************************
      ARS 유선구분 컨트롤 콜백 : ARS 컨트롤 비밀번호 체크 후 호출
    *****************************************************************************************/
    cbArsRltComplete ( obj ) {
      trace( "ARS 호출된 결과 값 : " + obj.resultYN );
      this.gArs_pswn_yn = obj.resultYN;
    },

    /**********************************************************************************
    * 조회 버튼클릭 처리 
    ***********************************************************************************/
    fn_searchList () {
      if(this.fn_ValidateCheck("S")) {
        this.vService_gubun = "S";
        this.fn_Search();
      }
    },

    /**********************************************************************************
    * 처리 버튼클릭 처리 
    ***********************************************************************************/
    fn_process () {
      //등록
      if(this.fn_ValidateCheck("I")) {
        this.vService_gubun = "I";
        this.fn_Save();
      }
    },

    /**************************************************************************************
    * 예금주 확인
    ***************************************************************************************/
    btn_cust_nm_onclick ()
    {
      if ( this.fn_CU1190_value_Cust_Check() == false ) {
        return;
      }

      // check_yn = 'Y';
      
      this.Ds_2040_head = Object.assign({}, DataSet305M.Ds_2040_head )
      this.Ds_2040_head.cust_no = this.lv_ed_cust_no.value
      this.Ds_2040_head.bank_cd = this.Cob_btrans_bank_cd
      this.Ds_2040_head.btrans_acct_no = this.Ed_btrans_acct_no
      this.Ds_2040_head.btrans_acct_nm = this.Ed_btrans_acct_nm
      this.Ds_2040_head.btrans_req_rname_no = this.Ed_rname_no
      
      // svcId = "selectRP204000_dpstr";
      // //서버에 호출 할 정보를 셋팅해준다.
      // var sSvcID        = svcId;
      // var sURL          = "svc::"+svcId+".do";		// svc = http://localhost:8080/
      // var sInDatasets   = "rp204000_i_00vo=Ds_2040_head";
      // var sOutDatasets  = "Ds_1190_body_data=rp204000_o_00vo";
      // var sArgument     = "";
      // var sCallbackFunc = "fn_call_back_cust";
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570216_S'
      this.eaiCommObj.params = this.Ds_2040_head
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_cust)

    },

    /**************************************************************************************
    * 예금주 Call Back 함수
    ***************************************************************************************/
    fn_call_back_cust ( pResultData ) {
      let t_data = pResultData.data
      this.Ed_cust_nm = t_data.cust_nm

      if( t_data.error_msg != '809900' ) {
        let t_popupObj = {}
        let t_valList = []
        t_popupObj.content = FSMessageUtil.commMessage(t_valList)[t_data.error_msg]
        this.fn_AlertPopup(0, t_popupObj)
        return false
      }
    },

    /**************************************************************************************
    * 예금주 확인 체크
    ***************************************************************************************/
    fn_CU1190_value_Cust_Check ()
    {
      if( FSCommUtil.gfn_length(FSCommUtil.gfn_trim(this.lv_ed_cust_no.value)) != 7) {
        FSCommUtil.fn_validate( this, [{ content: '고객번호를 입력해 주십시오.', ref:'ed_cust_no' }] )
        return false
      }

      if ( FSCommUtil.gfn_length(this.Ed_btrans_acct_no) == 0) {
        FSCommUtil.fn_validate( this, [{ content: '계좌번호 입력오류입니다.', ref:'Ed_btrans_acct_no' }] )
        return false
      }
      
      if ( FSCommUtil.gfn_length(this.Ed_btrans_acct_nm) == 0) {
        FSCommUtil.fn_validate( this, [{ content: '예금주명을 입력해 주십시오.', ref:'Ed_btrans_acct_nm' }] )
        return false
      }

      if ( FSCommUtil.gfn_length(this.Cob_btrans_bank_cd) == 0) {
        FSCommUtil.fn_validate( this, [{ content: '은행코드를 선택해 주십시오.', ref:'Cob_btrans_bank_cd' }] )
        return false
      }
      
      return true;
    },

    /**************************************************************************************
    * 조회 체크 함수
    * 사용처 안보임
    ***************************************************************************************/
    // fn_CU1190_value_Check ()
    // {
    //   if( FSCommUtil.gfn_length(FSCommUtil.gfn_trim(this.lv_ed_cust_no.value)) != 7) {
    //       this.gfn_getMessage("Alert","고객번호를 입력해 주십시오.");
    //       this.$refs['ed_cust_no'].focus()
    //     return false;
    //   }

    //   if ( FSCommUtil.gfn_length(this.Div02.Ed_btrans_acct_no.value) == 0) {
    //       this.gfn_getMessage("Alert","계좌번호 입력오류입니다.");
    //       this.Div02.Ed_btrans_acct_no.setFocus();
    //       return false;
    //   }
    //   return true;
    // },

    /**************************************************************************************
    * 조회
    ***************************************************************************************/
    fn_Search ()
    {
      // 화면 초기화
      this.fn_init('search') // 컨텐츠 영역만 초기화
      // 데이터셋 초기화
      this.Ds_1190_detail = ''
      this.Ds_1190_head.proc_gb = "S2"
      this.Ds_1190_head.secure_passv = this.Ed_password
      this.Ds_1190_head.secure_encsymm = 'SEED'
      this.Ds_1190_head.cust_no = this.lv_ed_cust_no.value


      // var secure_passv = nexacro.secureSession.encipherEx(this.Ed_password);
      // this.Ds_1190_head.setColumn(0, "secure_encsymm", nexacro.secureSession.getKeyIV()); //ENCSYMM
      // this.Ds_1190_head.setColumn(0, "secure_passv", secure_passv);
      // this.Ds_1190_head.setColumn(0, "ars_pswn_yn", gArs_pswn_yn);
      
      // svcId = "selectCU119000_btransAccnList";
      // //서버에 호출 할 정보를 셋팅해준다.
      // var sSvcID        = svcId;
      // var sURL          = "svc::"+svcId+".do";		// svc = http://localhost:8080/
      // var sInDatasets   = "cu119000_i_00vo=Ds_1190_head";
      // var sOutDatasets  = "Ds_1190_detail=cu119000_accnList";
      // var sArgument     = "";
      // var sCallbackFunc = "fn_call_back";
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570238_S'
      this.eaiCommObj.params = this.Ds_1190_head
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back)
    },

    fn_call_back ( pResultData ) {
      let t_data = pResultData.data
      console.log(t_data)
       
      if( t_data.error_msg != '809900' && t_data.error_msg != '809990' ) {
        // this.lv_confirm_btn = false // 확인버튼 활성화
        let t_valList = []
        let contentMsg = FSMessageUtil.commMessage(t_valList,t_data.error_msg)[t_data.error_msg.substring(0,6)]
        this.fn_AlertPopup(0, {content: contentMsg})
        return false
      }

      // this.lv_confirm_btn = false // 확인버튼 활성화
      //     t_popupObj.content = FSMessageUtil.commMessage(t_valList,t_data.error_msg)[t_data.error_msg.substring(0,6)]
      //     this.fn_AlertPopup(0, t_popupObj)
      //     return false
      
      // 결과 셋팅
      this.Ds_1190_detail = t_data.cu119000_O_00VO

      //this.Ds_1190_detail.deleteAll();
      const eaiId = pResultData.commHeaderVO.eaiId
      if(eaiId == "C391_F10570238_S") { //selectCU119000_btransAccnList
        this.enabledList.Cob_btrans_bank_cd = true
        this.enabledList.Ed_btrans_acct_no = true
        this.enabledList.btn_cust_nm = true
        this.enabledList.Rad_per_gb = true
        this.Ed_btrans_acct_nm = this.cust_nm1
        

        if ( this.gUser_group_cd == "90" ) {
          this.enabledList.Ed_btrans_acct_nm = true
          // 명의자 구분 '본인'이고 ADMIN(90)인 경우에는 예금주체크값을 선택할 수 있음
          // this.visibleList.Chk_btrans_acct_nm_diff_yn = true  // TOBE 요청
        }
        this.enabledList.btn_process = true
      } // ASR201100155_약정이체등록시 해제여부 세팅 로직 보완 START 		 
      else if(eaiId == "insertCU119000_btransAcctInfo") {
        this.fn_searchList() ;
      }
      // ASR201100155_약정이체등록시 해제여부 세팅 로직 보완 END 
     
    },

    /**************************************************************************************
    * 등록
    ***************************************************************************************/
    fn_Save () {
      // var secure_passv = nexacro.secureSession.encipherEx(this.Ed_password);
      // this.Ds_1190_head.setColumn(0, "secure_encsymm", nexacro.secureSession.getKeyIV()); //ENCSYMM
      // this.Ds_1190_head.setColumn(0, "secure_passv", secure_passv);
      // this.Ds_1190_head.setColumn(0, "aprv_sno", '');
      // this.Ds_1190_head.setColumn(0, "sup_id", '');
      // this.Ds_1190_head.setColumn(0, "proc_gb", "I");
      // this.Ds_1190_head.setColumn(0, "ars_pswn_yn", gArs_pswn_yn);
      // this.Ds_1190_detail.clearData();
      
      // svcId = "insertCU119000_btransAcctInfo";
      // //서버에 호출 할 정보를 셋팅해준다.
      // var sSvcID        = svcId;
      // var sURL          = "svc::"+svcId+".do";		// svc = http://localhost:8080/
      // var sInDatasets   = "cu119000_i_00vo=Ds_1190_head";
      // var sOutDatasets  = "Ds_1190_detail=cu119000_list gds_admission_info=admission_info DS_FORM_Head=report_list";// 서식데이터(전자금융거래등신청서)
      // var sArgument     = "";
      // var sCallbackFunc = "fn_call_back_insert";
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

      this.Ds_1190_head.secure_passv = this.Ed_password
      this.Ds_1190_head.secure_encsymm = 'SEED'
      this.Ds_1190_head.aprv_sno = ''
      this.Ds_1190_head.sup_id = ''
      this.Ds_1190_head.proc_gb = "I"      
      this.Ds_1190_head.dpstr_chk_cd = this.Chk_btrans_acct_nm_diff_yn == true ? '2' : '1'
      this.Ds_1190_head.per_gb = this.Rad_per_gb
      this.Ds_1190_head.bnk_accn_no_encr = this.Ed_btrans_acct_no
      this.Ds_1190_head.bank_acct_nm = this.Ed_btrans_acct_nm
      this.Ds_1190_head.trd_bank_cd = this.Cob_btrans_bank_cd
      this.Ds_1190_head.cust_no = this.lv_ed_cust_no.value

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570239_S'
      this.eaiCommObj.params = this.Ds_1190_head
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_insert)
    },

    fn_call_back_insert ( pResultData ) {
      console.log('==fn_call_back_insert==')
      console.log(pResultData)
      let t_data = pResultData.data
      
      // 리포트 데이터 초기화
      Object.assign(this.DS_FORM_HEAD, t_data) 
      // Object.assign(this.DS_FORM_HEAD, DataSet305M.DS_FORM_HEAD) 
      // this.DS_FORM_HEAD.form_no        = FSCommUtil.gfn_nullToEmpty(t_data.form_no) // NULL 일 경우 빈 값을 리턴한다
      // this.DS_FORM_HEAD.issue_tr_cd    = FSCommUtil.gfn_nullToEmpty(t_data.issue_tr_cd)
      // this.DS_FORM_HEAD.yyyy           = FSCommUtil.gfn_nullToEmpty(t_data.yyyy)
      // this.DS_FORM_HEAD.mm             = FSCommUtil.gfn_nullToEmpty(t_data.mm)
      // this.DS_FORM_HEAD.dd             = FSCommUtil.gfn_nullToEmpty(t_data.dd)
      // this.DS_FORM_HEAD.dept_nm        = FSCommUtil.gfn_nullToEmpty(t_data.dept_nm)
      // this.DS_FORM_HEAD.emp_nm         = FSCommUtil.gfn_nullToEmpty(t_data.emp_nm)
      // this.DS_FORM_HEAD.dept_telno     = FSCommUtil.gfn_nullToEmpty(t_data.dept_telno)
      // this.DS_FORM_HEAD.cust_nm        = FSCommUtil.gfn_nullToEmpty(t_data.cust_nm)
      // this.DS_FORM_HEAD.cust_no        = FSCommUtil.gfn_nullToEmpty(t_data.cust_no)
      // this.DS_FORM_HEAD.rname_no       = FSCommUtil.gfn_nullToEmpty(t_data.rname_no)
      // this.DS_FORM_HEAD.rname_no_gb    = FSCommUtil.gfn_nullToEmpty(t_data.rname_no_gb)
      // this.DS_FORM_HEAD.trd_bank_nm    = FSCommUtil.gfn_nullToEmpty(t_data.trd_bank_nm)
      // this.DS_FORM_HEAD.bank_acct_no   = FSCommUtil.gfn_nullToEmpty(t_data.bank_acct_no)
      // this.DS_FORM_HEAD.bfcer_cust_no  = FSCommUtil.gfn_nullToEmpty(t_data.bfcer_cust_no)
      // this.DS_FORM_HEAD.issnc_hstr_sno = FSCommUtil.gfn_nullToEmpty(t_data.issnc_hstr_sno)

      let regexp_number = /^[0-9]+$/ // 숫자

      if( t_data.error_msg != '809901' ) {
        if ( t_data.error_msg  == '데이터를 확인하세요. IS_BANKINFO-ZZ_DPSTR_CHK_CD' ) {
          this.fn_AlertPopup(0, {content:'예금주명이 상이합니다. 상이버튼을 눌러주세요.'})  
        } else if ( regexp_number.test(t_data.error_msg) == true ) { 
          let t_popupObj = {}
          let t_valList = []
          t_popupObj.content = FSMessageUtil.commMessage(t_valList)[t_data.error_msg]
          this.fn_AlertPopup(0, t_popupObj)
        } else {
          this.fn_AlertPopup(0, {content:t_data.error_msg})  
        }
        return false
      }

      this.gArs_pswn_yn = "";
      let tmpContentTitle = ''
      let tmpContent = ['약정이체계좌등록이 완료되었습니다.']      
      
      let lv_Vm = this
      let t_popupObj = {
        confirm: true,                  
        confirmFunc: lv_Vm.fn_ReportPrint,
        content: tmpContent,
        contentTitle: tmpContentTitle,
        confirmData:{
          bfcer_cust_no: lv_Vm.lv_ed_cust_no.value
        },
      }

      this.fn_AlertPopup(9, t_popupObj)

      // TODO 여기부터 이성룡 부장님 백단 확인후 진행
      // if ( t_data.gds_admission_info.length > 0 ) {
      //   this.gfn_requestAdmission(this, this.fn_Save);
      // } else {
      //   // this.$refs.div_ars.fn_init( this );		/* 초기화 */
      //   this.gArs_pswn_yn = "";
      //   if ( this.lv_isProcess ) {
      //     this.fn_AlertPopup(0, {content:'약정이체계좌등록이 완료되었습니다.본인인증 후 전자서명을 진행합니다.'})
      //   } else {
      //     this.fn_AlertPopup(0, {content:'약정이체계좌등록이 완료되었습니다.'})
      //     // this.fn_ReportData();
      //   }
      // }
    },    

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint () {
      console.log('fn_ReportPrint....')
      // this.$bizUtil.fsUtils.saveCvrPblInfo(this, this.DS_FORM_HEAD)

      /**
       * 고객용 정보조회
       * 전자서식 본인인증, 수령방법 팝업을 위한 필수값 셋팅
       */
      // this.$bizUtil.fsUtils.selCustInfo(this, {bfcer_cust_no: this.lv_ed_cust_no.value})

      let formList = []
      let FS000003 = {formId: 'FS000003'} // FS000003_전자금융거래 등 신청서
      let FS000004 = {formId: 'FS000004'} // FS000004_전자금융거래약관
      formList.push(FS000004)

      let searchData = Object.assign({}, this.DS_FORM_HEAD) 
      delete searchData.fossadmissionVO // 리포트시 사용하지 않는 값
      searchData.issue_tr_cd        = 'CU1190'
      searchData.title_gubun2       = 'V' //약정이체
      searchData.service2_gubun1    = ''  //신청
      searchData.service2_gubun3    = ''  //해지

      if(this.vService_gubun === 'I'){
        searchData.service2_gubun1  = 'V'
      }else if(this.vService_gubun === 'D'){
        searchData.service2_gubun3  = 'V'
      }

      FS000003.params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'reportCO070500_Info',
      }
      FS000003.mappingData = {
        acct_nm: this.Ed_btrans_acct_nm, // 예금주명
      }
      formList.push(FS000003)
      
      if (this.lv_isProcess){
        // 그룹신규 - 약정이체 마지막화면 설정        
        this.getStore('fsStore').dispatch('IS_LAST_START')
      }
      FSInfoUtil.commReportInfo(this, formList)
    },

    fn_ValidateCheck ( val ) {
      if (val == "S") {
        if ( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7) {
          FSCommUtil.fn_validate( this, [{ content: '고객번호 입력오류입니다.', ref:'ed_cust_no' }] )
          return false;
        }

        if ( this.isMobile ) {
          if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_masked_val))) {
              FSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력하십시요.', ref:'Ed_password' }] )
              return false;
            } else if (FSCommUtil.gfn_length(this.lv_masked_val) < 4) {
              FSCommUtil.fn_validate( this, [{ content: '비밀번호를 다시 입력하십시요.', ref:'Ed_password' }] )
              return false;
            }
        } else {
          if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.Ed_password))) {
            FSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력하십시요', ref:'Ed_password' }] )
            return false;
          } else if (FSCommUtil.gfn_length(this.Ed_password) < 4) {
            FSCommUtil.fn_validate( this, [{ content: '비밀번호를 다시 입력하십시요', ref:'Ed_password' }] )
            return false;
          }
        }
      }
      else if (val == "I") {
      
        if ( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7) {
          FSCommUtil.fn_validate( this, [{ content: '고객번호 입력오류입니다', ref:'ed_cust_no' }] )
          return false;
        }
      
        if( this.Rdo_ars_gb == "N" ) 
        {
          if ( this.isMobile ) {
            if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_masked_val))) {
              FSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력하십시요', ref:'Ed_password' }] )
              return false;
            } else if (FSCommUtil.gfn_length(this.lv_masked_val) < 4) {
              FSCommUtil.fn_validate( this, [{ content: '비밀번호를 다시 입력하십시요.', ref:'Ed_password' }] )
              return false;
            }
          } else {
            if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.Ed_password))) {
              FSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력하십시요.', ref:'Ed_password' }] )
              return false;
            } else if (FSCommUtil.gfn_length(this.Ed_password) < 4) {
              FSCommUtil.fn_validate( this, [{ content: '비밀번호를 다시 입력하십시요.', ref:'Ed_password' }] )
              return false;
            }
          }
        }

        if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.Ed_btrans_acct_nm))) {
          FSCommUtil.fn_validate( this, [{ content: '예금주명을 입력하십시요.', ref:'Ed_btrans_acct_nm' }] )
          return false;
        }

        if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.Ed_cust_nm))) {
          FSCommUtil.fn_validate( this, [{ content: '예금주 확인이 필요합니다.', ref:'Ed_cust_nm' }] )
          return false;
        }

        if ( this.gUser_group_cd != "90" && this.Rad_per_gb == "P" && this.Ed_btrans_acct_nm != this.cust_nm1 ) {
          FSCommUtil.fn_validate( this, [{ content: '예금주명이 일치하지 않습니다.', ref:'Ed_btrans_acct_nm' }] )
          return false;
        }

        if ( FSCommUtil.gfn_isNull(this.lv_rdo_agnt_chk_yn.value) ){
          FSCommUtil.fn_validate( this, [{ content: '대리인여부을 선택하십시오.', ref:'rdo_agnt_chk_yn' }] )
          return;
        }

      }
      return true;
    },

    // Ed_btrans_acct_no_onchar ()
    // {
    //   var nChar = this.gfn_isNum(e.chartext);
    //   if(!nChar) {        //0-9까지아니고  
    //     this.gfn_getMessage("Alert","숫자 이외에 다른 문자는 입력이 허용되지 않습니다. 숫자만 	입력해 주세요.");
    //     this.Div02.Ed_btrans_acct_no.set_value("");
    //     return;
    //   }
    // },
    Ed_btrans_acct_onkeydown (e) 
    {
      if(e.keyCode == 13){
        this.btn_cust_nm_onclick()
      }
    },

    Rad_per_gb_onitemchanged ()
    {
      // ASR190501021_약정이체계좌등록시 조건값 추가 요청 2019.06.18
      // 명의자 구분 '본인'이고 ADMIN(90)인 경우에는 예금주체크값을 선택할 수 있음
      // this.visibleList.Chk_btrans_acct_nm_diff_yn = false // TOBE요청 
      this.Chk_btrans_acct_nm_diff_yn = false

      if(this.Rad_per_gb == "P" ){ // 본인
        this.Ed_btrans_acct_nm = this.cust_nm1
        this.enabledList.btn_cust_nm = true
        // ASR180100150 #1190 조회항목 변경 요청 2018.02.13 by 문장혁 시작
        /* 본인계좌로 등록할 때 조회된 예금주명이 두음법칙 등으로 등록을 못할 경우
        * Admin 권한일 때만 입력 예금주명 란을 열어줌
        * RT출금의 경우에는 계좌주BP로 등록하여서라도 이체 처리가 가능한 반면,
        * RT입금의 경우에는 BP에 등록안된 새로운 고객 은행계좌를 RT계좌로 등록하려고 하면
        * 먼저 BP에 계좌 등록을 해야 하는데 두음 법칙 등의 이유로 본인/타인 구분 '본인'일 때
        * 입력예금주명이 조회된 예금주명과 상이한 경우 살짝 고쳐 BP에 등록해볼 수 있도록 열어줌.
        this.Div02.Ed_btrans_acct_nm.set_enable(false);
        */
        if ( this.gUser_group_cd == "90" ) {
          this.enabledList.Ed_btrans_acct_nm = true
          
          // ASR190501021_약정이체계좌등록시 조건값 추가 요청 2019.06.18
          // 명의자 구분 '본인'이고 ADMIN(90)인 경우에는 예금주체크값을 선택할 수 있음
          // this.visibleList.Chk_btrans_acct_nm_diff_yn = true // TOBE 요청
        }
        else {
          this.enabledList.Ed_btrans_acct_nm = false
        }
        // ASR180100150 #1190 조회항목 변경 요청 2018.02.13 by 문장혁 끝
        
        // ASR171200158 약정계좌등록 프로세스 변경 2018.01.02 by 문장혁 시작
        /* 화면의 실명번호 필요 없음 (2017.11.07 타인 계좌 등록 시 예금주 체크 반영 시
        * 실명번호 입력을 추가하였으나 다시 필요 없어짐
        this.Div02.Ed_rname_no.set_value("");
        this.Div02.Ed_rname_no.set_enable(false);
        */
        
        // TOBE 요청사항 명의자 구분 없이 다 enable 되게
        //this.enabledList.btn_cust_nm = true		// 본인일 경우에만 열어줌

        // ASR171200158 약정계좌등록 프로세스 변경 2018.01.02 by 문장혁 종료
      } else if(this.Rad_per_gb == "O" ){ // 타인
        this.Ed_btrans_acct_nm = ''
        this.enabledList.Ed_btrans_acct_nm = true
        this.enabledList.btn_cust_nm = false
        // ASR171200158 약정계좌등록 프로세스 변경 2018.01.02 by 문장혁 시작
        /* 화면의 실명번호 필요 없음 (2017.11.07 타인 계좌 등록 시 예금주 체크 반영 시
        * 실명번호 입력을 추가하였으나 다시 필요 없어짐
        this.Div02.Ed_rname_no.set_value("");
        this.Div02.Ed_rname_no.set_enable(true);
        */
        
        // TOBE 요청사항 명의자 구분 없이 다 enable 되게
        // this.enabledList.btn_cust_nm = false		// 타인일 경우 예금주 조회 수행 안함
        
        // ASR171200158 약정계좌등록 프로세스 변경 2018.01.02 by 문장혁 종료
      }
      
    },


    btn_rp2010_onclick () {
      var paObjList = {};
      paObjList.rmvl_srn_id = "CU119000";
      paObjList.bfcer_cust_no = this.lv_ed_cust_no.value;
      this.gfn_openMenuId( "RP201000", paObjList);
    },

    div_search_Ed_password_onsetfocus () {
      // 1 ARS, 2 pinpad
      if( this.$refs.div_ars.getValue() == "Y" ) {
        this.$refs.div_ars.fn_requestARS();										/* ARS에 비밀번호 입력 요청 */
      } else {
        this.div_device.fn_G_pinpadInput(obj, "2");	
      }
    },

    // modal
    openModal(type) {
      for(let i=1; i<101; i++) {
        this["modal"+type].open(); break;
      }
    },
    closeModal(type) {
      for(let i=1; i<101; i++) {
        this["modal"+type].close(); break;
      }
    },

    editFile() {
      // Native 편집모드 실행
    },

    deleteFile() {
      this.openModal(1)
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {        
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : ars 컴포넌트에서 callback
     ******************************************************************************/
    fn_pwdEnable_callBack ( bool ) {
      this.ctrlPwd = bool
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : ars 컴포넌트에서 callback
     ******************************************************************************/
    fn_focus ( refName ) {
      this.$refs[refName].focus()
      // this.$refs['ed_cust_no'].focus()
    },
    
    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event, flag) {
      console.log('fn_SearchValid.....')

      let t_cust_no
      if( flag == 'P' ) {
        t_cust_no = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,7)
      } else {
        t_cust_no = this.lv_ed_cust_no.value.replace(/[^0-9]/g, '').substr(0,7)
      }

      if( FSCommUtil.gfn_length(t_cust_no) == 7 ) {
        if( FSCommUtil.gfn_isNum(event.key) || event.key =='Enter' || event.key == 'v' || this.isMobile ) {
          // 고객명 초기화
          this.cust_nm1 = ''

          // Ed_cust_no_OnChar() 로직 추가
          this.gArs_pswn_yn = "";
          // this.gfn_cust_no(this.div_search.cust_nm1, e.pretext, e.chartext, e.posttext, "fn_callBack");
          let t_data = {bfcer_cust_no : this.lv_ed_cust_no.value}
          FSInfoUtil.commInfo(this, 1, t_data, this.fn_callBack)
        }
      }

      // 고객정보 펼치기 정보 초기화
      // this.$refs.fsCustInfo.fn_Init()
    },

    /***************************************************************************************
      CALLBACK
    *****************************************************************************************/
    fn_callBack ( pResultData )
    { 
      
      let t_data = pResultData.data

      if( t_data.error_msg != '809900' ) {
        return false
      } else {
        if( FSCommUtil.gfn_length(t_data.sy100500_O_00VO) > 0 ){
          if( FSCommUtil.gfn_isNull( FSCommUtil.gfn_trim(t_data.sy100500_O_00VO[0].fnd_cust_nm) ) ){

            let t_popupObj = {content:'해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.'}
            this.fn_AlertPopup(0, t_popupObj)
            
            this.lv_ed_cust_no.value = ''
            this.cust_nm1 = ''
            this.cust_nm2 = ''
            return;
          }

          if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7 ) {
            if(!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
              FSCommUtil.fn_validate( this, [{ content: '고객번호 형식에 맞지 않습니다.', ref:'ed_cust_no' }] )
              return 
            } else {
              // 고객정보조회
              this.fn_CustInfo()
            }
          }
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 고객정보
     ******************************************************************************/
    fn_OpenPopup(type, pData, subId) {
      switch (type) {
        case 0:
          this.$refs.popup308.fn_Open()
          break          
        case 'MSPFS313P':
          // 업무대리인 조회 팝업
          this.pPopup313Obj = pData
          this.$refs.popup313.fn_Open()
          break        
      }
    },
    

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 고객정보, 'MSPFS510P' : 본인인증
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 0:
          console.log('고객정보 callback...')

          // 고객번호 매핑 후 고객정보조회
          this.lv_ed_cust_no.value = pData
          this.fn_SearchValid({key: 'v'})
          break        
      }
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_IsActive() {
      this.isActive = !this.isActive;
    },
    
    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....')

      this.pCustInfoObj.custNo = this.lv_ed_cust_no.value
      this.$refs.fsCustInfo.fn_CustInfoSearch()
    },

    fn_SetEdCustName(val) {
      // 고객명
      this.cust_nm1 = val
    },

    /******************************************************************************
     * Function명 : fn_AgentCheck
     * 설명       : 대리인 만기체크
     ******************************************************************************/
    fn_AgentCheck() {
      console.log('fn_AgentCheck...')

      if( FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) ) {
        this.$refs['ed_cust_no'].focus()
        this.lv_rdo_agnt_chk_yn.value = ''
        return
      } else if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 ) {
        this.$refs['ed_cust_no'].focus()
        this.lv_rdo_agnt_chk_yn.value = ''
        return
      }

      let t_params = {
        // PO 호출 조회조건
        bfcer_cust_no : this.lv_ed_cust_no.value,
        tr_code : 'CU119000',

        // 추가정보
        menu_id : 'MSPFS305M',
        btn_id: 'lv_rdo_agnt_chk_yn',
      }
      FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
    },

    /******************************************************************************
     * Function명 : fn_endProc
     * 설명       : 프로세스일 경우 전자서식만 호출    
     ******************************************************************************/    
    fn_endProc() {        
      
      // 그룹신규 - 약정이체 마지막화면 설정        
      /**
       * 고객용 정보조회
       * 전자서식 본인인증, 수령방법 팝업을 위한 필수값 셋팅
       */

      // this.$bizUtil.fsUtils.selCustInfo(this, {bfcer_cust_no: this.lv_ed_cust_no.value})
      // this.getStore('fsStore').dispatch('IS_LAST_START')

      // this.$bizUtil.fsUtils.eltrnProcess(this)
      
      // let formList = []
      // let FS99999 = {formId: 'FS99999'} 
      // formList.push(FS99999)

      // let formList = []
      // let FS000003 = {formId: 'FS000003'} // FS000003_전자금융거래 등 신청서
      // FSInfoUtil.commReportInfo(this, formList)
      let lv_obj = {
        cancel : false,
        content: ['그룹신규 프로세스가 완료되었습니다.'],
        contentTitle: '그룹신규 완료', 
      } 
      this.$refs.fsHeader.fn_GoHome(lv_obj)
    },

    /***************************************************************************************
      벨리데이션 error css, 메시지 처리 함수 (기본형)
      param : 배열 오브젝트
      return : boolean / true : 정상 , flase : 체킹됨

      내부 함수 localValid 리턴값을 리턴한다
      단건 호출시 : FSCommUtil.fn_validate( this, [{ title: '정정구분', ref:'cmb_modifyGb' }] )
                        조건에 의한 단건 호출이기때문에 mValue 없이 다음과 같은 형식으로 호출
    *****************************************************************************************/
  /**
   * FSCommutil로 뺌 테스트 운영 문제 없을시 삭제
   */
    // fn_validate ( pParam ) {
      
    //   // console.log(window.vue.getStore('userInfo').getters.getUserInfo) // getStore sesseion 정보
      
    //   let pValidArry = [];
    //   if ( pParam.length > 0 ) { // 값이 없을 경우 기본 디폴트값 설정 또는 무시
    //     pValidArry = pParam
    //   } 
      
    //   // 실행 및 리턴
    //   const lv_vm = this
    //   let rtnBool = true
    //   let chekcedCnt = 0
    //   for ( let idx in pValidArry ) {
    //     // console.log(this.validCheck[arguments[i]])
    //     let modelObj = pValidArry[idx]
    //     if(FSCommUtil.gfn_isNull(modelObj.mValue)){ // 객체에 mValue 빈값 또는 존재 하지 않을시
    //       try {
    //         const lv_el = lv_vm.$refs[modelObj.ref].$el
    //         // const thidDom = lv_vm.$refs[modelObj.ref].$el
    //         if ( ! lv_el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
    //           lv_el.classList.add('error')
    //         }
    //         if ( ! FSCommUtil.gfn_isNull( modelObj.title ) ) {  // 스낵바 메시지는와 포커스는 하나만 출력
    //         } else if ( ! FSCommUtil.gfn_isNull( modelObj.content ) ) {
    //         } else {
    //         }
            
    //         if ( chekcedCnt == 0 ) { // 첫번째 항목만 포커스
    //           FSCommUtil.gfn_focus(lv_vm.$refs[modelObj.ref])
    //         }
    //       } catch (error) {
    //         console.log(modelObj.ref + '실패')
    //         continue
    //       }
    //       rtnBool = false
    //       chekcedCnt++
    //     }
    //   }
    //   return rtnBool
    // },

  }
};
</script>
<style scoped>
</style>