/*
  DataSet 
 ***********************************************************************************
 *       수정일         이름         사유
 ***********************************************************************************
 *    2023.03.15.       지재호      dataSet 외부파일로 정리
 
 */
 const DataSet305M = {}

 DataSet305M.Ds_cust_info = {
  custid: '',
  custNm: '',
  ssnDob: '',
  ssnFin: '',
  eventNo: '',
  eventCustNm: '',
  eventRegNo: '',
  telNo: '',
  hpNo: '',
  sagoCd: '',
  sagoTxt: '',
  apndCmpy: '',
  apndYmd: '',
  remvCd: '',
  remvTxt: '',
  remvCmpy: '',
  remvYmd: '',
  eventStat: '',
  fill: '',
  flagRtCd: '',
}

DataSet305M.Ds_cu1190 = {
  cust_no: '',
  cust_nm: '',
}

DataSet305M.DS_form_list = {
  form_no: '',
  form_nm: '',
}

DataSet305M.Ds_cu1190_per_gb = {
  code: '',
  name: '',
}

DataSet305M.Ds_1190_head = {
  tr_code: '',
  proc_gb: '',
  cust_no: '',
  secure_encsymm: '',
  secure_passv: '',
  rlnm_cnf_no_encr: '',
  trd_bank_cd: '',
  bnk_accn_no_encr: '',
  bank_acct_nm: '',
  per_gb: '',
  dpstr_chk_cd: '',
  aprv_sno: '0',
  sup_id: '',
  ars_pswn_yn: '',
}

DataSet305M.Ds_cu1190_head = {
  tr_code: '',
  proc_gb: '',
  cust_no: '',
  secure_encsymm: '',
  secure_passv: '',
  rlnm_cnf_no_encr: '',
  trd_bank_cd: '',
  bnk_accn_no_encr: '',
  bank_acct_nm: '',
  per_gb: '',
  dpstr_chk_cd: '',
  aprv_sno: '',
  sup_id: '',
  ars_pswn_yn: '',
}

DataSet305M.Ds_cu1190_detail_tmp = {
  proc_gb: '',
  cust_no: '',
  trd_bank_cd: '',
  bnk_accn_no_encr: '',
  bank_acct_nm: '',
  acct_id: '',
}

DataSet305M.Ds_2040_head = {
  cust_no: '',
  bank_cd: '',
  btrans_acct_no: '',
  btrans_acct_nm: '',
  btrans_req_rname_no: '',
}

DataSet305M.DS_FORM_HEAD = {
  form_no: '',
  issue_tr_cd: '',
  yyyy: '',
  mm: '',
  dd: '',
  dept_nm: '',
  emp_nm: '',
  dept_telno: '',
  cust_nm: '',
  cust_no: '',
  rname_no: '',
  rname_no_gb: '',
  trd_bank_nm: '',
  bank_acct_no: '',
  bfcer_cust_no: '',
  issnc_hstr_sno: '',
}



 export default DataSet305M
// eslint-disable-next-line